import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import AccountSettingsDetail from '@/components/AccountSettingsDetail';

export function SettingsIndex() {
  const { user } = useAuth0();

  return (
    <AppFrame organizationId={user?.nickname}>
      <AccountSettingsDetail />
    </AppFrame>
  );
}

export default withAuthenticationRequired(SettingsIndex);
