import { useAuth0 } from '@auth0/auth0-react';

import AccountSettingsDetailView from '@/components/AccountSettingsDetail/AccountSettingsDetailView';

const defaultProps = {};

type AccountSettingsDetailContainerProps = {
  location: Location;
} & typeof defaultProps;

// eslint-disable-next-line no-empty-pattern
export function AccountSettingsDetailContainer({}: AccountSettingsDetailContainerProps) {
  const { user } = useAuth0();
  const account = {};

  return (
    <AccountSettingsDetailView
      account={account}
      error={false}
      loading={false}
      user={user}
    />
  );
}

AccountSettingsDetailContainer.defaultProps = defaultProps;

export default AccountSettingsDetailContainer;
