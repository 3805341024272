import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';

const defaultProps = {};

type AccountSettingsDetailViewProps = {
  account: any;
  error: any;
  loading: boolean;
  user: any;
} & typeof defaultProps;

export function AccountSettingsDetailView({
  account,
  error,
  loading,
  user,
}: AccountSettingsDetailViewProps) {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/settings`}
            underline="hover"
          >
            {user?.nickname}
          </Link>
          <Typography color="text.primary" sx={{ minWidth: `50px` }}>
            {t(`settings.settings`)}
          </Typography>
        </Breadcrumbs>
        {!error && (
          <Stack
            direction={{ xs: `row`, sm: `row` }}
            justifyContent="space-between"
          >
            <Box>
              <Typography
                color="inherit"
                component="div"
                variant="h1"
                sx={{ minWidth: `400px`, mb: { xs: 1, sm: 0 } }}
              >
                {user?.nickname}
              </Typography>
            </Box>
          </Stack>
        )}
      </Box>
      <Box>
        {loading ? (
          <PageLoading></PageLoading>
        ) : error ? (
          <PageError>{error.message}</PageError>
        ) : account ? (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Paper elevation={0} component={Card}>
                <CardHeader title="Details" sx={{ px: 0, py: 1.5 }} />
                <CardContent sx={{ p: 0 }}>
                  <List>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemText
                        sx={{ maxWidth: 1 / 6 }}
                        primaryTypographyProps={{ sx: { color: `grey.700` } }}
                        primary={`ID`}
                      />
                      <ListItemText primary={`TEST!!!!`} />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions></CardActions>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

AccountSettingsDetailView.defaultProps = defaultProps;

export default AccountSettingsDetailView;
